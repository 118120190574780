
import WidgetList from "@/components/widgets/WidgetList"

import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"

export default {
  name: "Widgets",
  meta: {
    pageId: AnalyticsEventPageId.Widgets,
  },
  components: {
    WidgetList,
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
